<template>
    <v-main class="container-prosker">
        <v-icon class="mt-5 mb-0" @click="goBack">mdi-arrow-left</v-icon>
        <v-row>
            <v-col cols="12">
                <div class="d-flex flex-column align-center">
                    <h2 class="font-weight-bold black--text mt-0 same-line">
                        {{ userData.first_name }} {{ userData.last_name }}
                    </h2>
                    <div class="userContainer">
                        <div :class="userData.profile_img ? '' : 'defaultImageUser'" @mouseout="hideButton"
                              class="userPicture rounded-circle overflow-hidden" @mouseover="showButton"
                              :style="(userData.profile_img) ? { 'background-image': 'url(' + userData.profile_img + ')' } : {}">
                          <div ref="buttonCamera" class="d-flex justify-content-center align-items-center w-100 h-100">
                            <user-profile-image-dialog :on-close="onAddImageClose" :isAdminEditing="true" :userId="userData.id" :new-item="newProfileImage" :show-add="showAddImage"/>
                          </div>
                        </div>
                    </div>
                    <v-rating v-model="getRating" background-color="#59D79D" color="#F2D022" readonly size="24" />
                    <span class="green-prosk font-size-14">{{ userData.count_works_done }} {{ $t("worksDone") }}
                    </span>
                </div>
            </v-col>
            <template>
                <v-expansion-panels v-model="panel" multiple class="mb-10">
                    <UserInfoPanel :userData="userData" :fetchUser="fetchUser" :isLoading="isLoadingUserInformation" />
                    <UserInfoAddressPanel :userData="userData" :fetchUser="fetchUser" :isLoading="isLoadingUserInformation" />
                    <UserInfoCategoriesPanel :userData="userData" :fetchUser="fetchUser" :isLoading="isLoadingUserInformation" v-show="userData.type_user >= $options.USER_TYPE_PROSKER" />
                    <UserInfoSkillsPanel :userData="userData" :fetchUser="fetchUser" :isLoading="isLoadingUserInformation" v-show="userData.type_user >= $options.USER_TYPE_PROSKER" />
                    <UserPlan :userData="userData" :fetchUser="fetchUser" :isLoading="isLoadingUserInformation" v-show="userData.type_user >= $options.USER_TYPE_PROSKER" />
                </v-expansion-panels>
            </template>
        </v-row>
    </v-main>
</template>
<script>
    import axios from 'axios';
    import UserProfileImageDialog from '@/components/socialvue/dialogs/UserProfileImageDialog';
    import errorDialog from '@/components/socialvue/mixins/errorDialog';
    import UserInfoPanel from '@/views/Pages/Admin/EditUsers/UserInfoPanel';
    import UserInfoAddressPanel from '@/views/Pages/Admin/EditUsers/UserInfoAddressPanel';
    import UserInfoCategoriesPanel from '@/views/Pages/Admin/EditUsers/UserInfoCategoriesPanel';
    import UserInfoSkillsPanel from '@/views/Pages/Admin/EditUsers/UserInfoSkillsPanel';
    import UserPlan from '@/views/Pages/Admin/EditUsers/UserPlan';
    import {
        USER_TYPE_PROSKER
    } from '@/misc/constants';

    export default {
        components: {
            UserInfoPanel,
            UserInfoAddressPanel,
            UserInfoCategoriesPanel,
            UserInfoSkillsPanel,
            UserPlan,
            UserProfileImageDialog
        },
        data () {
            return {
                isLoadingUserInformation: false,
                userData: {},
                rating: 0,
                panel: [0],
                showAddImage: false
            };
        },
        created () {
            this.fetchUser();
            this.setImportedConstants();
        },
        mounted () {
            this.hideButton();
        },
        computed: {
            getRating () {
                return this.userData.rating || 0;
            }
        },
        mixins: [errorDialog],
        methods: {
            setImportedConstants () {
                this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
            },
            fetchUser () {
                this.isLoadingUserInformation = true;
                const userId = this.$route.params.id;
                const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}`;
                axios
                    .get(url)
                    .then((response) => {
                        this.userData = response.data.data;
                        this.isLoadingUserInformation = false;
                    })
                    .catch((error) => {
                        this.showError(error);
                        this.isLoadingUserInformation = false;
                    });
            },
            goBack () {
                this.$router.go(-1)
            },
            showButton () {
              if (this.$refs.buttonCamera) {
                this.$refs.buttonCamera.style.marginTop = '0px';
              }
            },
            hideButton () {
              if (this.$refs.buttonCamera) {
                this.$refs.buttonCamera.style.marginTop = '180px';
              }
            },
            newProfileImage () {
                this.showAddImage = true;
            },
            onAddImageClose () {
                this.showAddImage = false;
                this.fetchUser();
            }
        }
    };
</script>
<style scoped>
    .userPicture {
        height: 150px;
        width: 150px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
</style>
